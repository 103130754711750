// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from '@nuxt/types'

const mergeQueryParamsMiddleware: Middleware = ({ route }) => {
  const sessionParams = sessionStorage.getItem('SearchQueryParams')
  const inputParams = new URLSearchParams(sessionParams || '')

  const currentParams = route.query

  Object.entries(currentParams).forEach(([ key, queryParameter ]) => {
    if (queryParameter !== undefined) {
      if (Array.isArray(queryParameter)) {
        queryParameter.forEach((value) => {
          if (value && (
            !inputParams.has(key) || !inputParams.getAll(key).includes(value)
          )) {
            inputParams.set(key, value)
          }
        })
      } else if (!inputParams.has(key) || !inputParams.getAll(key).includes(queryParameter)) {
        inputParams.set(key, queryParameter)
      }
    }
  })

  sessionStorage.setItem('SearchQueryParams', inputParams.toString())
}

export default mergeQueryParamsMiddleware
